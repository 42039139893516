import React from "react";
import { Link } from "react-router-dom";
import ButtonOutline from "../../../components/ui/Buttons/ButtonOutline";
import ButtonPrimary from "../../../components/ui/Buttons/ButtonPrimary";
import "./navbar.scss";
function Navbar(props) {
  return (
    <div>
      <div>
        <div className="container py-4">
          <div className="d-flex justify-content-between">
            <div>
              <img
                src={require("../../../assets/images/truckpedia/logo.png")}
                alt="logo"
              />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <Link to="/">
                <span className="text-decoration-none color-primary">
                  How it Works
                </span>
              </Link>
              <Link to="/login">
                <ButtonOutline title="LOGIN" className="color-primary" />
              </Link>
              <Link to="/register">
                <ButtonPrimary title="SIGNUP" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
