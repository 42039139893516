import React from "react";
import "./index.scss";
import email from "../../../assets/images/footer/email.svg";
import phone from "../../../assets/images/footer/phone.svg";
import marker from "../../../assets/images/footer/marker.svg";
import { Link } from "react-router-dom";
const navItemJson = [
  { url: "/instant-quote", name: "Instant Quote" },
  { url: "/about", name: "About" },
  { url: "/contact-us", name: "Contact Us" },
  { url: "/tracking", name: "Tracking" },
  { url: "/blogs", name: "Blog" },
];

function Footer() {
  return (
    <div id="template-footer">
      {/* <Newsletter /> */}
      <div className="container p-5">
        <div className="d-flex justify-content-center">
          <div className="component">
            <img
              src={require("../../../assets/images/footer/logo.png")}
              className="text-center --image"
              alt="img"
            />
          </div>
        </div>
        <div>
          <div className="row mt-5">
            <div className="col-md-3">
              <div>
                <h5 className="font-weight-bold">Menu</h5>

                <ul className="list-group">
                  {navItemJson.map((item) => {
                    return (
                      <li key={item.name}>
                        <Link to={item.url}>
                          <span className="text-dark">{item.name}</span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <h5 className="font-weight-bold">Contact</h5>
              <ul className="list-group contact-address">
                <li className="component icon">
                  <img alt="marker" src={marker} />
                  <span className="component ms-3">
                    <span className="--text">
                      12 Ave, Washington 165, NY CA 54003
                    </span>
                  </span>
                </li>
                <li className="icon">
                  <img alt="marker" src={email} />
                  <span className="component ms-3">
                    <span className="--text">info@company.com</span>
                  </span>
                </li>
                <li className="component icon">
                  <img alt="marker" src={phone} />
                  <span className="component ms-3">
                    <span className="--text">(123) 456-7890</span>
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <div className="component">
                <span className="--text color-small h6">
                  All trucking services are provided by ABC Trucking, Inc., a
                  FMCSA licensed property broker and freight forwarder USDOT
                  #123456, MC #123456. All transactions are subject to our terms
                  of service.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
