import React from "react";
import "./index.scss";
function CompanyCard({ className }) {
  return (
    <div className={`company-card ${className}`}>
      <div className="component">
      <img src={require("../../../assets/images/contact-us/us_flag.png")} alt="img" className="--image" width={150} height={150}/>
      </div>
      <div className="component">
        <h4 className="--text font-weight-bold mt-3">United States</h4>
      </div>
      <div className="component">
        <p className="--text color-small mt-3">
          2307 Beverley Rd Brooklyn, New York 11226 USA
        </p>
      </div>
    </div>
  );
}

export default CompanyCard;
