import React, { useState, useEffect, useCallback } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import {
  Grid,
  Box,
  Typography,
  Pagination,
  Button,
  CardActions,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogList, clearBlogData } from "../../../stores/actions/templateActions";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import MyEditor from "./components/MyEditor";

function Blog() {
  const dispatch = useDispatch();
  const { loading, blogs, error, companyData } = useSelector(
    (state) => state.template
  );
  const [isEditBlog, setEditBlog] = useState(false);
  const [blogId, setBlogId] = useState();
  const [page, setPage] = useState(1);
  const pageSize = 3;
  const [totalPages, setTotalPages] = useState(0);
  const user = useSelector(
    (state) => state.user
  );
  const token = user.token && localStorage.getItem("access_token");

  const fetchBlogListData = useCallback(() => {
    const payload = {
      page,
      pageSize,
      companyId: companyData.carrierCompanyId,
    };
    dispatch(fetchBlogList(payload));
  }, [page, pageSize, companyData.carrierCompanyId, dispatch]);

  useEffect(() => {
    fetchBlogListData();
  }, [fetchBlogListData]);

  useEffect(() => {
    if (blogs && blogs.meta && blogs.meta.total) {
      setTotalPages(Math.ceil(blogs.meta.total / pageSize));
    }
  }, [blogs, pageSize]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: { error }</div>;
  }

  const handleBack = () => {
    dispatch(clearBlogData());
    setEditBlog(false);
    fetchBlogListData();
  }

  const handleBlogEdit = (blog) => {
    dispatch(clearBlogData());
    setEditBlog(true);
    setBlogId(blog.id)
  }
  return isEditBlog ? (
    <MyEditor onBack={ handleBack } navigateToBlogs={ handleBack } blogId={ blogId } />
  ) : (
    <div id="template" data-component-id="blog">
      <main className="--inner">
        <div className="component">
          <div className="hero-wrapper --bg-image">
            <div className="bg-blog-img">
              <img
                src={ require("../../../assets/images/about/hero_banner.jpg") }
                alt="bg img"
                className="about-bg-img --image"
              />
              <div>
                <p>Blog Posts</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Box sx={ { px: 8, mb: 12 } }>
            <Box sx={ { maxWidth: "screen-xl", mx: "auto" } }>
              { blogs && blogs.data && blogs.data.length > 0 ? (
                <Grid container spacing={ 2 }>
                  { blogs.data.map((post, index) => (
                    <Grid key={ index } item xs={ 12 } sm={ 6 } md={ 4 }>
                      <Card sx={ { maxWidth: 345 } }>
                        <Link to={ `/blog/${post.id}` }>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="170"
                              image="https://cdn.aglty.io/blog-starter-2021-template/posts/travel-photography_20210331143648_0.jpg"
                              alt="blog"
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="subtitle1"
                                component="div"
                                color={ "#000" }
                              >
                                { post.title }
                              </Typography>
                              {/* <Typography variant="body2" color="text.secondary">
                             Use this lines for description
                            </Typography> */}
                            </CardContent>
                          </CardActionArea>
                        </Link>
                        { token ?
                          <CardActions>
                            <Button
                              size="small"
                              color="primary"
                              onClick={ () => handleBlogEdit(post) }
                            >
                              Edit
                            </Button>
                          </CardActions> : null
                        }

                      </Card>
                      {/* <Link to={ `/blog/${post.id}` }>
                        <Box sx={ { display: 'flex', flexDirection: 'column', mb: 2 } }>
                          <Box sx={ { position: 'relative', height: '16rem', overflow: 'clip' } }>
                            <ImageList cols={ 1 }>
                              <ImageListItem>
                                <img
                                  src='https://cdn.aglty.io/blog-starter-2021-template/posts/travel-photography_20210331143648_0.jpg'
                                  alt={ post.title }
                                  style={ { objectFit: 'cover', borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem' } }
                                />
                              </ImageListItem>
                            </ImageList>
                          </Box>
                          <Box sx={ { bgcolor: 'grey.100', p: 8, border: 2, borderTop: 0 } }>
                            <Typography variant="h5" color="text.secondary" fontWeight="bold" mt={ 1 }>
                              { post.title }
                            </Typography>
                          </Box>
                        </Box>
                      </Link> */}
                    </Grid>
                  )) }
                </Grid>
              ) : null }
              { totalPages ? (
                <Box sx={ { display: "flex", justifyContent: "center", mt: 4 } }>
                  <Pagination
                    count={ totalPages }
                    page={ page }
                    onChange={ handleChangePage }
                    color="primary"
                  />
                </Box>
              ) : null }
            </Box>
          </Box>
        </div>
      </main>
    </div>
  );
}

export default Blog;
