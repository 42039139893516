import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ButtonOutline from "../../../components/ui/Buttons/ButtonOutline";
import ButtonPrimary from "../../../components/ui/Buttons/ButtonPrimary";
import "./index.scss";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Input from "../../../components/ui/Truckpedia/Input/Input";
import { loginCarrierTruckpedia } from "../../../api/auth";
import { toast, ToastContainer } from "react-toastify";
import { connect, useSelector } from "react-redux";
import { setUserData } from "../../../stores/actions/userActions";
import { useParams } from "react-router";
import { appConstants } from "../../../constants/constants";
// import $ from "jquery";
const TemplateNavbar = ({ user, setUserData, level, template }) => {
  const { businessURL } = useParams();
  const templatePayload = useSelector(
    (state) => state?.template?.templatePayload
  );
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [navigationUrls, setNavigationUrls] = useState([]);
  const [companyName] = useState("ABC Trucking");

  let checkCarrierForWebsiteBuilder =
    window.location.hostname.split(".")[0] === "carriers";

  const isShowEditButton = () => {
    if (user.token || checkCarrierForWebsiteBuilder) {
      if (level !== null || !level) {
        return true;
      }
    }
    return false;
  };

  const getNavItems = () => {
    let webpages = [];
    const hasWebpages = templatePayload.content.some((item) =>
      item.hasOwnProperty("webpages")
    );
    if (hasWebpages) {
      const getAllWebpages = templatePayload.content.filter(
        (item) => item.webpages
      );
      const [result] = getAllWebpages;
      webpages = result?.webpages;
    }
    let navItemJson = webpages?.map((item) => {
      let foundItem = appConstants.webpages.find((d) => d.route === item);
      if (foundItem) {
        return { url: foundItem.route, name: foundItem.title };
      }
      return {};
    });
    navItemJson = [
      { url: "/instant-quote", name: "Instant Quote" },
      ...navItemJson,
    ];
    let navItemJsonCarrier = webpages?.map((item) => {
      let foundItem = appConstants.webpages.find((d) => d.route === item);
      if (foundItem) {
        return {
          url: `/carrier/${businessURL}${foundItem.route}`,
          name: foundItem.title,
        };
      }
      return {};
    });
    navItemJsonCarrier = [
      { url: `/carrier/${businessURL}`, name: "Instant Quote" },
      ...navItemJsonCarrier,
    ];
    return { navItemJson, navItemJsonCarrier };
  };

  // const [rememberMe, setRememberMe] = useState(false)
  const token = user.token;
  useEffect(() => {
    if (businessURL) {
      setNavigationUrls(getNavItems().navItemJsonCarrier);
    } else {
      setNavigationUrls(getNavItems().navItemJson);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogin = () => {
    const payload = {
      email: email,
      password: password,
      remember_me: false,
    };
    loginCarrierTruckpedia(payload)
      .then((data) => {
        if (data.result) {
          localStorage.setItem("access_token", data.payload.access_token);
          // SuccessNotificationToast("You logged in successfully.");
          setUserData(data.payload);
          setOpen(false);
          if (!process.env.NODE_ENV || process.env.NODE_ENV !== "development") {
            window.location.href = `https://dashboard.truckpedia.io/login?access_token='${data.payload.access_token}'`;
          } else {
            window.location.href = `http://127.0.0.1:8000/login?access_token='${data.payload.access_token}'`;
          }
        } else {
          ErrorNotificationToast(data.message);
        }
      })
      .catch((error) => {
        ErrorNotificationToast(error.message);
      });
  };

  // const SuccessNotificationToast = (message) => {
  //   return toast.success(message, {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //     theme: "colored",
  //   });
  // };

  const ErrorNotificationToast = (message) => {
    return toast.error(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "colored",
    });
  };

  // const state = useSelector((state) => state.template);

  // useEffect(() => {
  //   const name = $(state.templatePayload.content)
  //     .find(".navbar-brand")
  //     .html();
  //   if (name) {
  //     setCompanyName(name);
  //   }
  // }, [state]);
  return (
    <div>
      <ToastContainer hideProgressBar />
      <nav
        className="navbar navbar-expand-lg bg-transparent"
        style={{ zIndex: 1 }}
        id="template-navbar"
      >
        <div className="container" style={{ borderBottom: "1px solid #fff8" }}>
          <div className="component">
            <span
              className="--text navbar-brand color-white bold"
              data-logo="true"
            >
              {companyName}
            </span>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarToggleExternalContent"
            aria-controls="navbarToggleExternalContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse p-4"
            id="navbarToggleExternalContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {navigationUrls.map((item) => {
                return (
                  <li className="nav-item mx-3" key={item.name}>
                    <Link to={item.url}>
                      <span className="nav-link color-white fw-medium">
                        {item.name}
                      </span>
                    </Link>
                  </li>
                );
              })}
              {isShowEditButton() && (
                <li className="nav-item mx-3">
                  <span className="nav-link color-white fw-medium nav-edit-button btn btn-primary">
                    Edit
                  </span>
                </li>
              )}
            </ul>
            {!token ? (
              <div className="d-flex gap-3">
                <ButtonOutline title="LOGIN" handleClick={handleClickOpen} />
                <ButtonPrimary title="SIGN UP" />
              </div>
            ) : null}
          </div>
        </div>
      </nav>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className="container">
            <div className="auth-card">
              <div>
                <h2 className="mb-3">Login</h2>
                <div>
                  <Input
                    label="Email address"
                    className="mt-3 fw-lighter"
                    onInput={(e) => setEmail(e.target.value)}
                  />
                  <Input
                    label="Password"
                    type="password"
                    className="mt-3"
                    onInput={(e) => setPassword(e.target.value)}
                  />

                  {/* <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label small-text-color small fw-light"
                      htmlFor="flexCheckDefault"
                    >
                      Remember Me
                    </label>
                  </div> */}

                  <div>
                    <ButtonPrimary
                      title="LOGIN"
                      handleClick={handleLogin}
                      className="w-100 mt-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setUserData: (user) => dispatch(setUserData(user)),
});

const mapStateToProps = (state) => ({
  user: state.user,
  level: state.accessLevel.level,
  template: state.template,
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateNavbar);
