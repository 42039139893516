import {
  getTemplateContentApi,
  createBlog,
  fetchBlogs,
  fetchBlogDetails,
  updateBlog,
} from "../../api/templateApi";
import {
  SET_TEMPLATE,
  FETCH_TEMPLATE_CONTENT,
  FETCH_TEMPLATE_FAILURE,
  FETCH_TEMPLATE_SUCCESS,
  SET_TEAMPLATE_SUCCESS,
  SET_CURRENT_ELEMENT_PROPERTIES,
  SET_ELEMENT_LAST_PROPERTIES,
  FETCH_TEMPLATE_CONTENT_LOADING_STOP,
  FETCH_TEMPLATE_CONTENT_LOADING,
  SET_TEAMPLATE_PAYLOAD,
  CLEAR_BLOG_DATA,
  SET_EQUIPMENT_TYPES_PAYLOAD,
  SET_WEBPAGES_PAYLOAD,
} from "../contents/actionsTypes";
import $ from "jquery";
import dragAndDrop from "../../utils/dragAndDrop";

export const fetchTemplateSuccess = (data) => ({
  type: FETCH_TEMPLATE_SUCCESS,
  payload: data,
});

export const fetchTemplateFailure = () => ({
  type: FETCH_TEMPLATE_FAILURE,
});

export const fetchTemplateContent = () => ({
  type: FETCH_TEMPLATE_CONTENT,
});

export const fetchTemplateContentLoading = () => ({
  type: FETCH_TEMPLATE_CONTENT_LOADING,
});

export const fetchTemplateContentLoadingStop = () => ({
  type: FETCH_TEMPLATE_CONTENT_LOADING_STOP,
});

export const setTemplateContentSuccess = (data) => ({
  type: SET_TEMPLATE,
  payload: data,
});

export const setCurrentElementProperties = (data) => ({
  type: SET_CURRENT_ELEMENT_PROPERTIES,
  payload: data,
});

export const elementLastProperty = (data) => ({
  type: SET_ELEMENT_LAST_PROPERTIES,
  payload: data,
});

export const uploadImageSuccess = (imageData) => ({
  type: "UPLOAD_IMAGE_SUCCESS",
  payload: imageData,
});

export const createBlogPostSuccess = (blogPost) => ({
  type: "CREATE_BLOG_POST_SUCCESS",
  payload: blogPost,
});

export const createBlogPostFailure = (error) => ({
  type: "CREATE_BLOG_POST_FAILURE",
  payload: error,
});

export const fetchBlogsRequest = () => ({
  type: "FETCH_BLOGS_REQUEST",
});

export const fetchBlogsSuccess = (blogs) => ({
  type: "FETCH_BLOGS_SUCCESS",
  payload: blogs,
});

export const fetchBlogsFailure = (error) => ({
  type: "FETCH_BLOGS_FAILURE",
  payload: error,
});

export const fetchBlogDetailsRequest = () => ({
  type: "FETCH_BLOG_DETAILS_REQUEST",
});

export const fetchBlogDetailsSuccess = (blogDetails) => ({
  type: "FETCH_BLOG_DETAILS_SUCCESS",
  payload: blogDetails,
});

export const fetchBlogDetailsFailure = (error) => ({
  type: "FETCH_BLOG_DETAILS_FAILURE",
  payload: error,
});

export const clearBlogData = () => ({
  type: CLEAR_BLOG_DATA,
});

export const setTemplateDataToHtml = () => async (dispatch, getState) => {
  try {
    const state = getState();
    dispatch(fetchTemplateContent());

    // set Section for normal text
    const sectionId = $("#template-content .component.active").attr(
      "section-id"
    );
    // set sectionId for card componants
    // if ($("#template-content .card-component.active").attr("section-id")) {
    //   sectionId = $("#template-content .card-component.active").find("section-id");
    // }
    const activeElement = $(".component.active");
    const contentTypeText = activeElement.find(".--text"); // Use this for active text component
    const contentTypeImage = activeElement.find(".--image"); // Use this for active image component
    const contentTypeLink = activeElement.find(".--link"); // Use this for active link component
    const contentDuplicateType = activeElement.data("duplicate-id"); // Use this for duplicate component
    const dataDuplicateId = activeElement.attr("data-duplicate-id");
    let content, blockId, properties;
    // set .--text
    if (contentTypeText.length > 0) {
      blockId = $(".active .--text").attr("block-id");
      content = $(`.component.active[section-id=${sectionId}] .--text`).text();
      properties = $(".active")
        .find(".--text")
        .css([
          "font-size",
          "color",
          "text-align",
          "text-transform",
          "font-weight",
          "margin",
          "padding",
          "line-height",
          "letter-spacing",
        ]);
    }
    //  set .--image
    if (contentTypeImage.length > 0) {
      blockId = contentTypeImage.attr("block-id");
      // content = $(`.component.active[section-id=${sectionId}] .--image`).attr("src");
      content = contentTypeImage.attr("src");
      const { width } = state.template.elementLastProperties;
      if (width !== $(".active").find(".--image").css("width")) {
        properties = $(".active").find(".--image").css(["width", "height"]);
      } else {
        properties = $(".active").find(".--image").css(["height"]);
      }
    }

    // // set content type TEXT
    // let contentType = "";

    // // set content type IMAGE
    // if (contentTypeImage.length > 0) {
    //   contentType = "IMAGE";
    // }

    // Payload for JSON
    const elementPayload = {
      sectionId: sectionId,
      blockId: blockId,
      contentType: "",
      content: content,
      style: properties,
      isDelete: false,
    };

    // set isNewText if te active text is new text
    if (contentTypeText.length > 0) {
      elementPayload.contentType = "TEXT";
      if ($(".component.active").data("new-text")) {
        elementPayload.isNewText = $(".component.active").data("new-text");
      }
      if ($(".component.active").find("[data-logo]").length > 0) {
        elementPayload.isLogo = true;
      }
    }
    if (contentTypeLink.length > 0) {
      elementPayload.contentType = "LINK";
      elementPayload.href = $(".active .--link").attr("href");
      elementPayload.target = $(".active .--link").attr("target");
    }
    if (dataDuplicateId) {
      // set Content type for DUPLICATE_TEXT
      if (contentTypeText.length > 0) {
        elementPayload.contentType = "DUPLICATE_TEXT";
        elementPayload.duplicate = contentDuplicateType;
      }
      // set Content type for DUPLICATE_IMAGE
      if (contentTypeImage.length > 0) {
        elementPayload.duplicate = contentDuplicateType;
      }
    }

    if (contentTypeImage.length > 0) {
      elementPayload.contentType = dataDuplicateId
        ? "DUPLICATE_IMAGE"
        : "IMAGE";
      if ($(".component.active").data("new-image")) {
        elementPayload.isNewImage = $(".component.active").data("new-image");
      }
    }
    dispatch(setTemplateContentSuccess(elementPayload));
    const jsonData = state.template.templatePayload.content;
    const existingIndexForWebpages = jsonData.findIndex((item) =>
      item.hasOwnProperty("webpages")
    );
    if (existingIndexForWebpages !== -1) {
      dispatch(setWebpages(jsonData[existingIndexForWebpages]?.webpages));
    }
  } catch (error) {
    dispatch(fetchTemplateFailure());
  }
};

export const setTemplateSuccess = (data) => ({
  type: SET_TEAMPLATE_SUCCESS,
  payload: data,
});

export const setTemplatePayload = (data) => ({
  type: SET_TEAMPLATE_PAYLOAD,
  payload: data,
});

export const deleteTemplateElement =
  (deleteId) => async (dispatch, getState) => {
    const state = getState();

    if ($(".card-component.active")) {
      $(".card-component.active").remove();
    }
    if ($(".component.active").parent()[0]?.nodeName === "LI") {
      $(".component.active").parent().remove();
    } else {
      if ($(".component.active").children().hasClass("percentage")) {
        $(".component.active").parent().remove();
      } else {
        $(".component.active").remove();
      }
    }
    const jsonData = state.template.templatePayload.content;
    jsonData.forEach((x, i) => {
      if (x.sectionId === deleteId) {
        jsonData[i].isDelete = true;
      }
    });

    $(".card-component").each((i, ele) => {
      if (
        $(ele).find(".--image").length === 0 &&
        $(ele).find(".--text").length === 0
      ) {
        $(ele).remove();
      }
    });
  };

export const setComponentTabsData = () => async (dispatch, getState) => {
  try {
    dispatch(fetchTemplateContent());
    const state = getState();
    const jsonData = state.template.templatePayload.content;
    const payload = {
      content: jsonData,
    };

    dispatch(setTemplateSuccess(payload));
    const elementsJson = jsonData.filter((item) => item.contentType);
    const clonnedJson = structuredClone(elementsJson);
    const reversedJson = clonnedJson.reverse();
    const sortedArray = reversedJson.sort((a, b) => {
      const firstId = a.sectionId.split("-")[a.sectionId.split("-").length - 1];
      const secondId =
        b.sectionId.split("-")[b.sectionId.split("-").length - 1];
      return firstId - secondId;
    });
    sortedArray.forEach((item) => {
      const existingDuplicateCheck = $(
        `#template-content [section-id=${item.sectionId}]`
      );

      // Set New TEXT
      if (item.isNewText) {
        if (!existingDuplicateCheck.length) {
          const insertText = `<div class='component' draggable='true' data-new-text="true" section-id=${item.sectionId} ><div class='--text' block-id=${item.blockId}></div></div>`;
          const page = item.sectionId.split("-")[1];
          $(`#template[data-component-id=${page}] .--inner`).append(insertText);
          const textElement = $(
            `#template-content .component[section-id=${item.sectionId}] .--text`
          );
          $(textElement).text(item.content).css(item.style);
          if (item.isDelete) {
            $(textElement).addClass("d-none");
          }
        }
      }
      // Add TEXT
      if (item.contentType === "TEXT" && !item.isNewText) {
        const text = $(
          `#template-content .component[section-id=${item.sectionId}] .--text`
        );
        if (item.isDelete) {
          $(text).addClass("d-none");
        } else {
          $(text).text(item.content).css(item.style);
        }
      }

      //  ADD LINK TO ELEMENT
      if (item.contentType === "LINK" && !item.isNewText) {
        $(`#template-content .component[section-id=${item.sectionId}] .--text`)
          .addClass("--link")
          .text(item.content)
          .css(item.style)
          .attr("href", item.href)
          .attr("target", item.target);
        if (!state.user.isLoggedIn) {
          $(
            `#template-content .component[section-id=${item.sectionId}] .--text`
          )
            .css({ cursor: "pointer" })
            .attr("onclick", `window.open('${item.href}', '${item.target}')`);
        }
      }

      //  ADD NEW IMAGE
      if (item.isNewImage) {
        if (!existingDuplicateCheck.length) {
          const insertImage = `<div class='component active' draggable='true' data-new-image="true" section-id=${item.sectionId}><img src='' class='--image img-placeholder' block-id='${item.blockId}' /></div>`;
          const page = item.sectionId.split("-")[1];
          $(`#template[data-component-id=${page}] .--inner`).append(
            insertImage
          );
          const image = $(
            `#template-content .component[section-id=${item.sectionId}] .--image`
          );
          if (item.isDelete) {
            $(image).parent().addClass("d-none");
          } else {
            $(image).attr("src", item.content).css(item.style);
          }
        }
      }

      // ADD IMAGE
      if (item.contentType === "IMAGE" && !item.isNewImage) {
        const image = $(
          `#template-content .component[section-id=${item.sectionId}] .--image`
        );
        if (item.isDelete) {
          $(image).addClass("d-none");
        } else {
          $(image).attr("src", item.content).css(item.style);
        }
      }
      //  ADD DUPLICATE TEXT
      if (
        item.contentType === "DUPLICATE_TEXT" &&
        !item.isLogo &&
        !item.isNewText
      ) {
        if (!existingDuplicateCheck.length) {
          const duplicate = $(
            `#template-content .component[section-id=${item.duplicate}]`
          ).clone(true);
          $(duplicate)
            .insertAfter(`.component[section-id=${item.duplicate}]`)
            .attr("section-id", item.sectionId)
            .attr("data-duplicate-id", item.duplicate)
            .children($(duplicate))
            .text(item.content)
            .css(item.style)
            .attr("block-id", item.blockId);

          if (item.isDelete) {
            $(duplicate).find(".--text").addClass("d-none");
          } else {
            $(duplicate).find(".--text").removeClass("d-none");
          }
        }
      }

      // ADD DUPLICATE IMAGE
      if (item.contentType === "DUPLICATE_IMAGE" && !item.isNewImage) {
        if (!existingDuplicateCheck.length) {
          const duplicate = $(
            `#template-content .component[section-id=${item.duplicate}]`
          ).clone(true);
          $(duplicate)
            .insertAfter(`.component[section-id=${item.duplicate}]`)
            .attr("section-id", item.sectionId)
            .attr("data-duplicate-id", item.duplicate)
            .children($(duplicate))
            .attr("src", item.content)
            .css(item.style)
            .attr("block-id", `${item.blockId}`);

          if (item.isDelete) {
            $(duplicate).find(".--image").addClass("d-none");
          } else {
            $(duplicate).find(".--image").removeClass("d-none");
          }
        }
      }
    });

    // This code sets all equipmentTypes when the content is loaded
    const existingIndex = jsonData.findIndex((item) =>
      item.hasOwnProperty("equipmentTypes")
    );
    if (existingIndex !== -1) {
      dispatch(setEquipmentTypes(jsonData[existingIndex]?.equipmentTypes));
    }

    if (!state.user.token) {
      $(".component").each((i, ele) => {
        $(ele)
          .removeClass("active")
          .removeAttr("draggable")
          .css("cursor", "auto");
      });
    } else {
      dragAndDrop();
    }
  } catch {
    dispatch(fetchTemplateFailure());
  }
};

export const getTemplateContent = (url) => async (dispatch) => {
  try {
    dispatch(fetchTemplateContent());
    const getContentPayload = {};
    if (url.hasOwnProperty("customDomain")) {
      getContentPayload.customDomain = url.customDomain;
      // getContentPayload.customDomain = url.customDomain.split(".io")[0]; use this with ngrok
    } else {
      getContentPayload.businessUrl = url.businessUrl;
    }
    dispatch(fetchTemplateContentLoading());
    const content = await getTemplateContentApi(getContentPayload);
    dispatch(fetchTemplateContentLoadingStop());

    const websiteContent = content.payload.website_content;
    const templateCompanyData = {
      carrierCompanyId: content.payload.carrierCompanyId,
      companyName: content.payload.companyName,
    };

    const payload = {
      content: JSON.parse(websiteContent),
    };

    dispatch(setTemplateSuccess(payload));
    dispatch(setTemplatePayload(templateCompanyData));
  } catch (error) {
    dispatch(fetchTemplateContentLoadingStop());
    dispatch(fetchTemplateFailure());
  } finally {
    dispatch(setComponentTabsData());
  }
};

export const setCurrentElementProperty = (payload) => async (dispatch) => {
  dispatch(setCurrentElementProperties(payload));
};

export const setEquipmentTypesPayload = (data) => ({
  type: SET_EQUIPMENT_TYPES_PAYLOAD,
  payload: data,
});

export const setEquipmentTypes = (payload) => async (dispatch) => {
  dispatch(setEquipmentTypesPayload(payload));
};

export const setWebpagesPayload = (data) => ({
  type: SET_WEBPAGES_PAYLOAD,
  payload: data,
});

export const setWebpages = (payload) => async (dispatch) => {
  dispatch(setWebpagesPayload(payload));
};

export const createBlogPost = (blogData) => async (dispatch) => {
  try {
    const response = await createBlog(blogData);
    dispatch(createBlogPostSuccess(response));
  } catch (error) {
    dispatch(createBlogPostFailure(error));
  }
};

export const updateBlogPost = (blogData) => async (dispatch) => {
  try {
    const response = await updateBlog(blogData);
    dispatch(createBlogPostSuccess(response));
  } catch (error) {
    dispatch(createBlogPostFailure(error));
  }
};

export const fetchBlogList = (payload) => async (dispatch, getState) => {
  dispatch(fetchBlogsRequest());
  try {
    const response = await fetchBlogs(payload);
    dispatch(fetchBlogsSuccess(response.payload));
  } catch (error) {
    dispatch(fetchBlogsFailure(error.message));
  }
};

export const fetchBlogPostDetail = (id) => async (dispatch) => {
  dispatch(fetchBlogDetailsRequest());
  try {
    const response = await fetchBlogDetails(id);
    dispatch(fetchBlogDetailsSuccess(response.payload));
  } catch (error) {
    dispatch(fetchBlogDetailsFailure(error.message));
  }
};
